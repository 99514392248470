import { IFetch } from "./fetch.hooks";
import { ISurveyRequests } from "../interfaces/apiRequests";
import { ISurvey, Survey } from "../models/survey.model";
import { ISurveysFilter } from "../models/filter.model";
import { IPagedResponseModel } from "../components/paginated/pagedResponse.model";

export function useSurveyRequests(http: IFetch): ISurveyRequests {
	async function GetOutstandingSupportRequests(): Promise<ISurvey[]> {
		const json = await http.Get<ISurvey[]>(`surveys/outstanding`);
		return json.map(i => Survey.Parse(i));
	}

	async function GetPaged(filter: ISurveysFilter): Promise<IPagedResponseModel<ISurvey>> {
		const response = await http.Post<IPagedResponseModel<ISurvey>, ISurveysFilter>("surveys/paged", filter);
		response.items = response.items.map(item => Survey.Parse(item));
		return response;
	}

	async function GetForTeam(teamId: number): Promise<ISurvey[]> {
		const json = await http.Get<ISurvey[]>(`surveys/team?teamId=${teamId}`);
		return json.map(i => Survey.Parse(i));
	}

	async function GetForEmployee(employeeId: number): Promise<ISurvey[]> {
		const json = await http.Get<ISurvey[]>(`surveys/employee?employeeId=${employeeId}`);
		return json.map(i => Survey.Parse(i));
	}

	async function GetById(id: number): Promise<ISurvey> {
		const json = await http.Get<ISurvey>(`surveys/${id}`);
		return Survey.Parse(json);
	}

	async function SendSurvey(id: number): Promise<ISurvey> {
		const json = await http.Post<ISurvey, void>(`surveys/send?employeeId=${id}`);
		return Survey.Parse(json);
	}

	async function ResendSms(surveyId: number): Promise<ISurvey> {
		const json = await http.Post<ISurvey, void>(`surveys/${surveyId}/resendsms`);
		return Survey.Parse(json);
	}

	async function Assign(surveyId: number, userId: string): Promise<ISurvey> {
		const json = await http.Patch<ISurvey>(`surveys/${surveyId}/assign?userId=${userId}`);
		return Survey.Parse(json);
	}

	async function Complete(surveyId: number, selectedReferrals: string[]): Promise<ISurvey> {
		const json = await http.Patch<ISurvey, string[]>(`surveys/${surveyId}/complete`, selectedReferrals);
		return Survey.Parse(json);
	}

	async function SendReminder(surveyId: number): Promise<ISurvey> {
		const json = await http.Post<ISurvey>(`surveys/${surveyId}/sendReminder`);
		return Survey.Parse(json);
	}

	async function Reopen(surveyId: number): Promise<ISurvey> {
		const json = await http.Post<ISurvey>(`surveys/${surveyId}/reopen`);
		return Survey.Parse(json);
	}

	return {
		GetPaged,
		GetOutstandingSupportRequests,
		GetForTeam,
		GetForEmployee,
		GetById,
		SendSurvey,
		ResendSms,
		Assign,
		Complete,
		SendReminder,
		Reopen
	};
}